import React from "react"
import { graphql } from "gatsby"

import "../styles/components/font.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        images {
          top_description
          top_image
        }
        example_images {
          example_description
          example_image
        }
        links {
          link_text
          link_url
        }
      }
      html
    }
  }
`

const Font = ({
  data: {
    markdownRemark: { frontmatter, html },
  },
}) => {
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="font">
        <div className="font__title">
          <h1>{frontmatter.title}</h1>
        </div>
        {frontmatter.images && (
          <div className="font__images">
            {frontmatter.images.map((image, i) => (
              <div className="font-image">
                <h4 className="font-image__title">{image.top_description}</h4>
                <img
                  key={`Font ${i}`}
                  src={`/${image.top_image}`}
                  alt={image.top_description}
                  className="font__image"
                />
              </div>
            ))}
          </div>
        )}
        <hr className="seperator" />
        <div
          className="font__content"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
        {frontmatter.example_images && (
          <div className="font_examples">
            {frontmatter.example_images.map((example, i) => (
              <img
                key={`Example ${i}`}
                src={`/${example.example_image}`}
                alt={example.example_description}
                className="font__example"
              />
            ))}
          </div>
        )}
        <hr className="seperator" />
        {frontmatter.links && (
          <div className="font__purchases">
            <h2>Purchase Links</h2>
            {frontmatter.links.map((link, i) => (
              <a
                key={`Link ${i}`}
                href={link.link_url}
                target="_blank"
                rel="noopener noreferrer"
                className="font__purchase"
              >{`- ${link.link_text}`}</a>
            ))}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Font
